<template>
  <div>
    <van-nav-bar
      title="提现"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
      right-text="提现记录"
      @click-right="onRightClick"
    />
    <div class="text-muted">
      可提现金额：{{ (total_amount - froze_amount).toFixed(2)
      }}<br />冻结金额：{{ froze_amount.toFixed(2) }}
    </div>

    <div class="my_tips">
      <b>用户须知：</b><br/>
      1.可提现金额≥100元即可提现，每月可提现一次，每次提现将提走全部可提现金额<br/>
      2.发起提现申请后，如果身份证、银行卡信息资料正确，一般在7个工作日内到账<br/>
      3.推广收入属劳务报酬，根据《中华人民共和国个人所得税法》，个人得到劳务报酬时需缴纳个税。相关个税的缴纳由快启动科技代为处理，以下是计算公式。<br/>
    <table class="my_tb">
        <tr><th colspan="3">个税计算公式</th></tr>
        <tr><td>税前劳务报酬(x)</td><td>税率</td><td>应扣个人所得税</td></tr>
        <tr><td>x&lt;=800</td><td>0</td><td>0</td></tr>
        <tr><td>800&lt;x&lt;=4000</td><td>0.2</td><td>(x-800)*0.2</td></tr>
        <tr><td>4000&lt;x&lt;=25000</td><td>0.2</td><td>(x-x*0.2)*0.2</td></tr>
        <tr><td>25000&lt;x&lt;=62500</td><td>0.3</td><td>((x-x*0.2)*0.3)-2000</td></tr>
        <tr><td>x&gt;62500</td><td>0.4</td><td>((x-x*0.2)*0.4)-7000</td></tr>
      </table>
    4.劳务报酬按月累加计算，一个月总收入超过800就需要缴税<br/>
    5.可提现金额 = 未提现总收入 - 冻结收入 - 退款扣除
    </div>

    <div style="margin: 16px">
        <van-button round block type="info" @click="doSave">提交申请</van-button>
    </div>
    <CopyRight></CopyRight> 
  </div>
</template>

<script>
import CopyRight from "@/components/CopyRight.vue";
import axios from "axios";
export default {
  data() {
    return {
      showOk: false,
      total_amount: 0.00,
      froze_amount: 0.00,
    };
  },

  components: {
    CopyRight,
  },

  mounted() {
    this.loadData();
  },

  methods: {
    onClickLeft() {
      this.$router.back();
    },

    onRightClick() {
      this.$router.push("/withdraw/list");
    },

    loadData() {
      let token = window.sessionStorage.getItem("token");
      axios
        .get(
          "api/withdraw/index?" +
            "token=" + token
        )
        .then((res) => {
          if (res.data.code == 0) {
            this.total_amount = res.data.data.total_amount;
            this.froze_amount = res.data.data.froze_amount;
          }else{
            this.$toast.fail(res.data.message);
          }
        })
    },

    doSave(){
      if(this.total_amount - this.froze_amount <= 100){
        this.$toast.fail("当前可提现金额不足100元，无法提现");
        return;
      }

      let token = window.sessionStorage.getItem("token");
      let postData = this.$qs.stringify({
            token:token,
        });

      axios
        .post("api/withdraw/apply", postData)
        .then((res) => {
          if(res.data.code == 0){
            this.$toast.success({
                duration: 2000, 
                forbidClick: true,
                message: '申请成功, 请等待客服处理，一般需要3-7个工作日'});
                setTimeout(function () {
                    window.location.reload();
                }, 2000);      
          }else{
            this.$toast.fail(res.data.message);
          }
        })
        .catch((err) => {
          console.log("apply err-->" + err);
          this.$toast.fail("申请失败");
        });
    },
  },
};
</script>
